import React, { useState } from "react";
import "./StatusSettings.scss";
import Select from "react-select";
import {
  CriterionParameter,
  DataType,
  FunctionDTO,
  FunctionStatus,
  StatusCriterionDTO,
} from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import { customStyles, optionsValue } from "./options";
import { ReactComponent as IconAddCriteria } from "./svg/AddCriteria.svg";
import { ReactComponent as TrashCan } from "./svg/trash-can.svg";
import { NumericFormat } from "react-number-format";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { Expander, ExpanderItem } from "@aws-amplify/ui-react";
import { customStylesFromValue } from "./customStyleForValue";
import Toggle from "../../TransportTable/Toggle/Toggle";

type StatusSettingsTypeProps = {
  statusFunctions: Array<FunctionStatus>;
  criteriaParameters: Array<CriterionParameter>;
  selectedRow: FunctionDTO;
  setSelectedRow: (row: FunctionDTO) => void;
  isDisabledFunction: boolean;
  dataType: DataType;
  formMethods: UseFormReturn<FunctionDTO>;
};

function StatusSettings(props: StatusSettingsTypeProps) {
  const intl = useIntl();
  const control = props.formMethods.control;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "statusCriteria",
  });
  const [selectedStatus, setSelectedStatus] = useState<{
    value: number | undefined;
    label: string | undefined;
  }>(
    props.statusFunctions
      .filter((s) => s.id !== 3)
      .map((s) => ({ value: s.id, label: s.name }))
      .find(
        (s) =>
          s.value ===
          (props.selectedRow.statusCriteria?.find(
            (x) => x.statusId !== undefined
          )?.statusId ?? -1)
      ) ?? props.statusFunctions.map((s) => ({ value: s.id, label: s.name }))[0]
  );
  const [idNewCriteria, setIdNewCriteria] = useState(-1);
  const [isHexEnabled, setIsHexEnabled] = useState(false);

  function addNewCriteria() {
    const newValue = new StatusCriterionDTO({
      id: idNewCriteria.toString(),
      uvi: props.selectedRow.uvi,
      functionId: props.selectedRow.id || "",
      parameterId: props.criteriaParameters[0].id!,
      statusId: selectedStatus?.value ?? -1,
      value: 0,
    });
    append(newValue);
    setIdNewCriteria(idNewCriteria - 1);
  }

  async function deleteCriteria(index: number) {
    console.log("До удаления:", props.selectedRow.statusCriteria);

    const updatedCriteria = [...props.selectedRow.statusCriteria!];
    updatedCriteria.splice(index, 1);

    const statusCriteriaToSend =
      updatedCriteria.length > 0 ? updatedCriteria : undefined;

    const updatedRow = new FunctionDTO({
      ...props.selectedRow,
      statusCriteria: statusCriteriaToSend,
    });

    try {
      console.log("Во время удаления: отправка данных на сервер");
      const response = await mapClient.updateFunction(updatedRow);

      if (!response?.succeeded) {
        console.error("Ошибка обновления критериев:", response.message);
      } else {
        props.setSelectedRow(updatedRow);
        console.log(
          "После удаления (серверный ответ):",
          updatedRow.statusCriteria
        );
      }
    } catch (error) {
      console.error("Ошибка обновления критериев:", error);
    }

    remove(index);
    console.log("После удаления (локальное состояние):", updatedCriteria);
  }

  const handleToggleChange = (enabled: boolean) => {
    setIsHexEnabled(enabled);
  };

  return (
    <Expander
      type="multiple"
      defaultValue={props.selectedRow.status?.id !== 3 ? ["item-1"] : []}
    >
      <ExpanderItem
        title={intl.formatMessage({ id: "status_settings_title" })}
        value="item-1"
      >
        <div className="settingsContainer">
          <div className="flex justify-end pt-4 pr-[62px]">
            <Toggle
              hexSelected={isHexEnabled}
              setHexEnabled={handleToggleChange}
            />
          </div>

          <div className="settingsBlockContainer">
            <div className="statusItem">
              <div className="mb-2">
                <span>
                  <FormattedMessage id="status_settings_choice" />
                </span>
              </div>
              <div>
                <Select
                  options={props.statusFunctions
                    .filter((s) => s.id !== 3)
                    .map((s) => ({ value: s.id, label: s.name }))}
                  onChange={(value) => setSelectedStatus(value!)}
                  value={selectedStatus}
                  isDisabled={props.isDisabledFunction}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="criteria">
              <div>
                <div className="criteriaHeader">
                  <span>
                    <FormattedMessage id="criteria_params_title" />
                  </span>
                </div>
              </div>
              <div className="criteriaParams">
                {fields
                  ?.filter((s) => s.statusId === selectedStatus?.value)
                  .map((s, index) => (
                    <div className="params" key={s.id}>
                      <Controller
                        name={`statusCriteria.${index}.parameterId`}
                        control={control}
                        rules={{
                          validate: (value) =>
                            props.dataType === DataType.Bit
                              ? value === 1 || value === 4
                                ? true
                                : intl.formatMessage({
                                    id: "validate_parameterId",
                                  })
                              : true,
                        }}
                        render={({
                          field: { value, onChange, name },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              name={name}
                              isSearchable={false}
                              isClearable={false}
                              options={props.criteriaParameters
                                .filter((c) =>
                                  props.dataType === DataType.Bit
                                    ? c.id === 1 || c.id === 4
                                    : true
                                )
                                .map((c) => ({ value: c.id, label: c.name }))}
                              value={
                                props.criteriaParameters
                                  .map((c) => ({ value: c.id, label: c.name }))
                                  .find((c) => c.value === value) ?? {
                                  value: "",
                                  label: "",
                                }
                              }
                              placeholder={intl.formatMessage({
                                id: "placeholder_criteria_params",
                              })}
                              onChange={(value) => {
                                onChange(value?.value ?? "");
                              }}
                              isDisabled={props.isDisabledFunction}
                              styles={customStyles}
                            />
                            <div className="mb-2.5">
                              {error && <p>{error.message || "Errors!!!"}</p>}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  ))}
              </div>
              <div className="btnAddCriteria">
                <button
                  className={`${
                    props.isDisabledFunction ||
                    (props.dataType === DataType.Bit && fields.length > 0)
                      ? "disabled"
                      : "active"
                  }`}
                  onClick={addNewCriteria}
                  disabled={
                    props.isDisabledFunction ||
                    (props.dataType === DataType.Bit && fields.length > 0)
                  }
                  type="button"
                >
                  <div className="addCriteriaContainer">
                    <IconAddCriteria />
                    <div
                      className={`ml-5 ${
                        props.isDisabledFunction ||
                        (props.dataType === DataType.Bit && fields.length > 0)
                          ? "disabled"
                          : "active"
                      }`}
                    >
                      <span>
                        <FormattedMessage id="add_criteria" />
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="criteriaValue">
              <div className="valueHeader">
                <span>
                  <FormattedMessage id="value_title" />
                </span>
              </div>
              <div className="valueBody">
                {fields
                  ?.filter((s) => s.statusId === selectedStatus?.value)
                  .map((v, index) => (
                    <div className="value" key={v.id}>
                      {props.dataType === DataType.Bit ? (
                        <div className="value_select">
                          <Controller
                            control={control}
                            name={`statusCriteria.${index}.value`}
                            rules={{
                              validate: (value) =>
                                props.dataType === DataType.Bit
                                  ? value === 0 || value === 1
                                    ? true
                                    : intl.formatMessage({
                                        id: "validate_value",
                                      })
                                  : true,
                            }}
                            render={({
                              field: { value, onChange, name },
                              fieldState: { error },
                            }) => (
                              <>
                                <Select
                                  name={name}
                                  options={optionsValue}
                                  styles={customStylesFromValue}
                                  isDisabled={props.isDisabledFunction}
                                  isSearchable={false}
                                  isClearable={false}
                                  placeholder={intl.formatMessage({
                                    id: "placeholder_value",
                                  })}
                                  value={{
                                    value: value,
                                    label: value?.toString() ?? "",
                                  }}
                                  onChange={(value) => {
                                    onChange(value?.value ?? 0);
                                  }}
                                />
                                <div className="mb-2.5">
                                  {error && (
                                    <p>{error.message || "Errors!!!"}</p>
                                  )}
                                </div>
                              </>
                            )}
                          />
                        </div>
                      ) : (
                        <Controller
                          control={control}
                          name={`statusCriteria.${index}.value`}
                          render={({ field: { value, onChange, name } }) => {
                            const displayValue = isHexEnabled
                              ? value.toString(16).toUpperCase()
                              : value.toString();

                            return (
                              <input
                                name={name}
                                className={`value_input ${props.isDisabledFunction ? "disabled" : ""}`}
                                value={displayValue}
                                onChange={(event) => {
                                  const inputValue =
                                    event.target.value.toUpperCase();

                                  if (!/^[0-9A-F]*$/.test(inputValue)) return;

                                  if (
                                    (props.dataType === DataType.Byte &&
                                      inputValue.length > 2) ||
                                    (props.dataType === DataType.TwoByte &&
                                      inputValue.length > 4)
                                  ) {
                                    return;
                                  }

                                  const parsedValue = isHexEnabled
                                    ? parseInt(inputValue, 16) || 0
                                    : parseInt(inputValue, 10) || 0;

                                  onChange(parsedValue);
                                }}
                                type="text"
                                placeholder={intl.formatMessage({
                                  id: "placeholder_value_input",
                                })}
                                disabled={props.isDisabledFunction}
                              />
                            );
                          }}
                        />
                      )}
                      <div className="remove_criteria pl-3">
                        <button
                          className="removeButton"
                          type="button"
                          onClick={() => deleteCriteria(index)}
                          disabled={props.isDisabledFunction}
                        >
                          <TrashCan />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ExpanderItem>
    </Expander>
  );
}

export default StatusSettings;
