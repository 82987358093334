import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./TransportEdit.scss";
import CustomMapContainer from "../Map/CustomMapContainer";
import Function from "./Fuction/Function";
import { FormattedMessage, useIntl } from "react-intl";
import TransportTable from "./TransportTable/TransportTable";
import { TransportEditorType } from "./TransportEditTypes";
import ButtonSave from "./buttons/ButtonSave";
import ButtonEdit from "./buttons/ButtonEdit";
import ButtonBack from "./buttons/ButtonBack";
import { FunctionDTO, VehicleDTO } from "../../api/auth/apiClient";
import { mapClient } from "../../api/auth/AxiosInstanse";
import Select from "react-select";
import { customStyles } from "./transportTypes/styles";
import Modal from "./Modal/Modal";
import { NotificationType } from "../Notification/notificationTypes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Notification from "../Notification/Notification";
import ModalNotification from "../CreateTransport/Modal_notification/ModalNotification";
import { UserRole } from "../Menu/Menu";
import SettingsModule from "./SettingsModule/SettingsModule";
import { ReactComponent as BtnSettings } from "./buttons/svg/settings.svg";
import ChronologyModal from "../ChronologyModal/ChronologyModal";
import FormatSelectionModal from "./FormatSelectionModal/FormatSelectionModal";

/*Редактирование транспорта*/
export const rolesForForms = [
  UserRole.Admin,
  UserRole.SystemAdmin,
  UserRole.Editor,
];

function TransportEdit(props: TransportEditorType) {
  const [isDisabledFunction, setIsDisabledFunction] = useState(true);
  const [isDisabledTransportEdit, setIsDisabledTransportEdit] = useState(true);
  const [buttonActive, setButtonActive] = useState(false);
  const [showModalTransportEdit, setModalTransportEdit] = useState(false);
  const intl = useIntl();
  const [transportBeforeEdit, setTransportBeforeEdit] = useState<VehicleDTO>();
  const [showDeleteFunction, setShowDeleteFunction] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormatModalOpen, setIsFormatModalOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState<"Standard" | "j1939">(
    "Standard"
  );
  const [isLoading, setIsLoading] = useState(false);

  const prevTransportIdRef = React.useRef<string | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (
      !props.activeTransport.id ||
      props.activeTransport.id === prevTransportIdRef.current ||
      !props.onShowTransportEditorList
    )
      return;

    prevTransportIdRef.current = props.activeTransport.id;
    setIsLoading(true);

    try {
      await Promise.all([
        props.getTransportById(props.activeTransport.id),
        props.getFunctionsList(props.activeTransport.uvi),
      ]);
    } catch (error) {
      console.error("Ошибка загрузки данных", error);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: "Ошибка загрузки данных",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    props.activeTransport.id,
    props.getTransportById,
    props.getFunctionsList,
    props.onShowTransportEditorList,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAddFunctionClick = () => setIsFormatModalOpen(true);

  const handleFormatSelect = (format: "Standard" | "j1939") => {
    setSelectedFormat(format);
    setIsFormatModalOpen(false);
    onChangeShowEditorTable(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<VehicleDTO>({
    mode: "onTouched",
    defaultValues: useMemo(
      () => props.activeTransport,
      [props.activeTransport]
    ),
  });

  useEffect(() => {
    if (props.activeTransport) {
      reset(props.activeTransport);
    }
  }, [props.activeTransport]);

  const roles = localStorage.getItem("roles")?.split(",");
  const currentUserRoles = roles?.map((role) => {
    switch (role.toLowerCase()) {
      case "systemadmin":
        return UserRole.SystemAdmin;
      case "admin":
        return UserRole.Admin;
      case "editor":
        return UserRole.Editor;
      case "viewer":
        return UserRole.Viewer;
      default:
        return UserRole.Viewer;
    }
  });

  const onChangeShowEditorTable = useCallback(
    (isDisabled: boolean) => {
      if (!props.activeTransport.uvi) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.warning,
          message: intl.formatMessage({ id: "check_uvi" }),
        });
        return;
      }

      setIsDisabledFunction(isDisabled);
      onShowEditorTable(true);
      props.setSelectedRow({ uvi: props.activeTransport.uvi } as FunctionDTO);
    },
    [props.activeTransport.uvi, props.onChangeNotificationState]
  );

  function onShowEditorTable(showTable: boolean) {
    props.setShowTable(showTable);
  }

  function onClose() {
    props.setActiveTransport(transportBeforeEdit!);
    props.onShowTransportEditorList(false);
  }

  function onChangeDisabled(isDisabledTransportEdit: boolean) {
    setIsDisabledTransportEdit(isDisabledTransportEdit);
    setTransportBeforeEdit({ ...props.activeTransport } as VehicleDTO);
  }

  function onClickButtonBack(showModal: boolean) {
    if (!isDisabledTransportEdit) {
      setModalTransportEdit(showModal);
    } else {
      onClose();
    }
    setButtonActive(!buttonActive);
  }

  const updateFunctionsAndTransport = useCallback(async () => {
    console.log("Функция updateFunctionsAndTransport вызвана");
    try {
      await Promise.all([
        props.getTransportById(props.activeTransport.id!),
        props.getFunctionsList(props.activeTransport.uvi),
      ]);

      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({
          id: "FunctionsAndTransportUpdateSuccess",
        }),
      });
    } catch {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "FunctionsAndTransportUpdateFail" }),
      });
    }
  }, [props.activeTransport.id, props.activeTransport.uvi]);

  /*Запрос на обновление транспорта*/
  const onSubmit: SubmitHandler<VehicleDTO> = async (data) => {
    try {
      await mapClient.updateVehicle(data);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "vehicle_change_success" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "duplicate_imei_vehicle" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await props.getTransport();
    onChangeDisabled(true);
  };

  /*Запрос на удаление функции из списка функций*/
  async function deleteFunctions(id: string) {
    setShowDeleteFunction(false);
    try {
      await mapClient.deleteFunction(id);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "function_delete_success" }),
      });
    } catch {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "function_delete_error" }),
      });
    }
    await props.getFunctionsList(props.activeTransport.uvi);
  }
  function onShowDeleteFunctionModal(row: FunctionDTO) {
    props.setSelectedRow(row);
    setShowDeleteFunction(true);
  }

  return (
    <div className="editorList">
      <FormatSelectionModal
        isOpen={isFormatModalOpen}
        onClose={() => setIsFormatModalOpen(false)}
        onSelect={handleFormatSelect}
      />

      {showModalTransportEdit ? (
        <div>
          <Modal onClickStayPage={onClickButtonBack} onClosePage={onClose} />
        </div>
      ) : null}
      {showDeleteFunction ? (
        <div>
          <ModalNotification
            modalQuestion={intl.formatMessage({ id: "deleteFunctionQuestion" })}
            onYesModalActions={() => setShowDeleteFunction(false)}
            onNoModalActions={() => deleteFunctions(props.selectedRow.id!)}
          />
        </div>
      ) : null}
      {props.showModuleSettings ? (
        <div>
          <SettingsModule
            onClickButtonBack={onClickButtonBack}
            buttonActive={buttonActive}
            onChangeDisabled={onChangeDisabled}
            isDisabled={isDisabledTransportEdit}
            activeTransport={props.activeTransport}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
            onShowTransportEditorList={props.onShowTransportEditorList}
            setShowModuleSettings={props.setShowModuleSettings}
            getModule={props.getModule}
            module={props.module}
            setModule={props.setModule}
            logModule={props.logModule}
            getLogModule={props.getLogModule}
          />
        </div>
      ) : null}
      {/*Форма для создания функции, Редактирования функции */}
      {props.showTable ? (
        <Function
          onShowEditorTable={onShowEditorTable}
          selectedRow={props.selectedRow}
          activeTransport={props.activeTransport}
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
          getFunctionsList={props.getFunctionsList}
          functionGroups={props.functionGroups}
          setFunctionGroups={props.setFunctionGroups}
          setSelectedRow={props.setSelectedRow}
          isDisabledFunction={isDisabledFunction}
          setIsDisabledFunction={setIsDisabledFunction}
          statusFunctions={props.statusFunctions}
          criteriaParameters={props.criteriaParameters}
          canIdentifiers={props.canIdentifiers}
          setCanIdentifiers={props.setCanIdentifiers}
          format={selectedFormat}
        />
      ) : null}
      {/*Редактирование выбранного транспорта*/}
      {!props.showTable && !props.showModuleSettings ? (
        <form onSubmit={handleSubmit(onSubmit)} className="editorContainer">
          {/*Заголовок, кнопки Редактировать и Сохранить*/}
          <div className="editorHeader">
            <ButtonBack
              onClickButtonBack={onClickButtonBack}
              buttonActive={buttonActive}
            />
            <h2>{props.activeTransport?.name ?? ""}</h2>
            {rolesForForms.filter((role) => currentUserRoles?.includes(role))
              .length > 0 ? (
              <>
                <button
                  type="button"
                  className="btn_settings ml-auto"
                  onClick={() => {
                    props.setShowTable(false);
                    props.setShowModuleSettings(true);
                  }}
                >
                  <BtnSettings />
                  <span className="ml-2.5">
                    <FormattedMessage id="settings_module_button" />
                  </span>
                </button>
                <ButtonSave />
                <ButtonEdit
                  onChangeDisabled={onChangeDisabled}
                  isDisabled={isDisabledTransportEdit}
                />
              </>
            ) : null}
          </div>
          {/*Поля:название транспорта, Vin, заметки, координаты транспорта*/}
          <div className="editorBlockContainer">
            <div className="itemBlock">
              <div className="descriptionBlock">
                <div>
                  <div className="mb-2 d-flex">
                    <div className="mb-1">
                      <span>
                        <FormattedMessage id="nameTransport" />
                      </span>
                    </div>
                    <input
                      {...register("name", {
                        required: intl.formatMessage({ id: "input_required" }),
                      })}
                      className={`${isDisabledTransportEdit ? "disabled" : ""}`}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "placeholder_name_transport",
                      })}
                      disabled={isDisabledTransportEdit}
                    />
                    <div className="mt-1">
                      {errors?.name && (
                        <p>{errors?.name?.message || "Errors!!!"}</p>
                      )}
                    </div>
                  </div>
                  <div className="mb-2 d-flex">
                    <div className="mb-1">
                      <span>
                        <FormattedMessage id="type_transport" />
                      </span>
                    </div>
                    <Controller
                      control={control}
                      name={"typeId"}
                      render={({ field: { value, onChange, name, ref } }) => (
                        <Select
                          name={name}
                          ref={ref}
                          options={props.typeTransport.map((t) => {
                            return { value: t.id!, label: t.type! };
                          })}
                          placeholder={intl.formatMessage({
                            id: "placeholder_type_transport",
                          })}
                          isSearchable={false}
                          isClearable={false}
                          styles={customStyles}
                          isDisabled={isDisabledTransportEdit}
                          onChange={(value) => {
                            onChange(value?.value);
                          }}
                          value={
                            props.typeTransport
                              .map((t) => {
                                return { value: t.id!, label: t.type! };
                              })
                              .find((t) => t.value === value) ?? undefined
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-2 d-flex">
                    <div className="mb-1">
                      <span>
                        <FormattedMessage id="vin" />
                      </span>
                    </div>
                    <input
                      {...register("vin")}
                      className={`${isDisabledTransportEdit ? "disabled" : ""}`}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "placeholder_Vin",
                      })}
                      disabled={isDisabledTransportEdit}
                    />
                  </div>
                  <div>
                    <div className="mb-1">
                      <span>
                        <FormattedMessage id="transport_UVI" />
                      </span>
                    </div>
                    <input
                      id="transportUVI"
                      name="transportUVI"
                      className="transport_UVI"
                      type="text"
                      readOnly
                      value={props.activeTransport.uvi}
                    />
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div className="mb-1">
                      <span>
                        <FormattedMessage id="number" />
                      </span>
                    </div>
                    <input
                      {...register("licensyPlate")}
                      className={`${isDisabledTransportEdit ? "disabled" : ""}`}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "placeholder_licensyPlate",
                      })}
                      disabled={isDisabledTransportEdit}
                    />
                  </div>
                </div>
                {/*Колонка с мини-картой*/}

                <div className="mapBlock">
                  <CustomMapContainer
                    coordinates={{
                      latitudeCenter:
                        props.activeTransport.coordinates?.latitude ?? 0,
                      longitudeCenter:
                        props.activeTransport.coordinates?.longitude ?? 0,
                      zoom: 10,
                    }}
                    carInfo={props.carInfo.filter(
                      (car) => car.id === props.activeTransport?.id
                    )}
                  />
                  {!isModalOpen && (
                    <button
                      onClick={openModal}
                      className="open-modal-button btn open-modal-button-size"
                    >
                      <img src="/image/chronology.svg" alt="chronology" />
                      {intl.formatMessage({ id: "chronology" })}
                    </button>
                  )}
                  <ChronologyModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    mapCenter={[
                      props.activeTransport.coordinates?.latitude ?? 0,
                      props.activeTransport.coordinates?.longitude ?? 0,
                    ]}
                    markerCoordinates={[
                      props.activeTransport.coordinates?.latitude ?? 0,
                      props.activeTransport.coordinates?.longitude ?? 0,
                    ]}
                    uvi={props.activeTransport.uvi}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Кнопки: Обновить, Добавить функцию*/}
          {rolesForForms.filter((role) => currentUserRoles?.includes(role))
            .length > 0 ? (
            <>
              <div className="flex justify-end mt-5 mb-5">
                <button
                  type="button"
                  onClick={updateFunctionsAndTransport}
                  className="btn"
                >
                  <span>
                    <FormattedMessage id="update_function" />
                  </span>
                </button>
                <button
                  type="button"
                  onClick={handleAddFunctionClick}
                  className="btn ml-5"
                >
                  <span>
                    <FormattedMessage id="add_function" />
                  </span>
                </button>
              </div>
            </>
          ) : null}

          {/*Таблица функций*/}
          <TransportTable
            {...props}
            functions={props.functions}
            filterFunctions={props.filterFunctions}
            onChangeShowEditorTable={onChangeShowEditorTable}
            selectedRow={props.selectedRow}
            activeTransport={props.activeTransport}
            setFilterFunctions={props.setFilterFunctions}
            deleteFunctions={deleteFunctions}
            functionGroups={props.functionGroups}
            setSelectedRow={props.setSelectedRow}
            onShowDeleteFunction={onShowDeleteFunctionModal}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
            setFormat={setSelectedFormat}
            isLoading={isLoading}
          />
        </form>
      ) : null}
      <Notification
        showNotification={props.showNotification}
        onChangeNotificationState={props.onChangeNotificationState}
      />
    </div>
  );
}

export default TransportEdit;
