import React, { useEffect, useMemo, useState } from "react";
import "./CanIdentifier.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as DeleteCan } from "./svg/trash.svg";
import { ReactComponent as EditCan } from "./svg/btnEditor.svg";
import { ReactComponent as CanConfirmedSuccess } from "./svg/canSuccess.svg";
import { ReactComponent as CanConfirmedError } from "./svg/canError.svg";
import { ReactComponent as WarningIcon } from "./svg/warningIcon.svg";
import { ReactComponent as CloseBtn } from "./svg/closeBtn.svg";
import { ReactComponent as ApplyBtn } from "./svg/apply.svg";
import { ReactComponent as AddNewCan } from "./svg/add_new_can.svg";
import DataTable, { TableColumn } from "react-data-table-component";
import { CanIdentifierDTO, FunctionDTO } from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../../Notification/notificationTypes";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import ModalNotification from "../../../CreateTransport/Modal_notification/ModalNotification";
import { customStylesCan } from "./stylesCan";
import FormatSelectionModal from "../../FormatSelectionModal/FormatSelectionModal";

type CanIdentifierProps = {
  canIdentifiers: Array<CanIdentifierDTO>;
  selectedRow: FunctionDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  setCanIdentifiers: (canIdentifiers: CanIdentifierDTO[]) => void;
  setShowModalCan: (isShow: boolean) => void;
  getFunctionsList: (uvi: string, id: string | undefined) => void;
  format: "Standard" | "j1939";
};

type ArrayCanIdentifierDTO = {
  array: CanIdentifierDTO[];
};

function CanIdentifier(props: CanIdentifierProps) {
  const intl = useIntl();
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [unconfirmedCanIds, setUnconfirmedCanIds] = useState<string[]>([]);
  const [showModalDeleteCan, setShowModalDeleteCan] = useState(false);
  const [showModalCancelAction, setShowModalCancelAction] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<ArrayCanIdentifierDTO>({
    mode: "all",
    defaultValues: useMemo(
      () => ({ array: props.canIdentifiers || [] }),
      [props.canIdentifiers]
    ),
  });

  useEffect(() => {
    if (props.canIdentifiers) {
      const total = props.canIdentifiers.length;
      const confirmed = props.canIdentifiers.filter(
        (item) => item.configurationConfirmed
      ).length;
      setTotalCount(total);
      setConfirmedCount(confirmed);
      setUnconfirmedCanIds(
        props.canIdentifiers
          .filter((item) => !item.configurationConfirmed)
          .map((item) => item.canId)
      );
    }
  }, [props.canIdentifiers]);
  useEffect(() => {
    if (props.selectedRow.uvi) {
      setCurrentUvi(props.selectedRow.uvi);
    }
  }, [props.selectedRow.uvi]);

  const { fields, append, remove } = useFieldArray({ control, name: "array" });
  const [canIdentifierToDelete, setCanIdentifierToDelete] = useState<number>();
  const [canIdentifierCanIndexToDelete, setCanIdentifierCanIndexToDelete] =
    useState<number>();
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false);
  const [isApplied, setIsApplied] = useState(true);
  const [isEditing, setIsEditing] = useState<{ [key: number]: boolean }>({});
  const [currentUvi, setCurrentUvi] = useState(props.selectedRow.uvi);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [isFormatModalOpen, setIsFormatModalOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState<"Standard" | "j1939">();
  const [pgnOptions, setPgnOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.selectedRow.uvi) {
      setCurrentUvi(props.selectedRow.uvi);
    }
  }, [props.selectedRow.uvi]);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const applyRow = () => {
    setIsEditingRow(true);
  };

  const startEditing = (index: number) => {
    setEditingIndex(index);
    setIsEditingRow(true);
    setIsButtonsDisabled(true);
    setIsEditing({ [index]: true });
  };

  const editRow = () => {
    if (editingIndex !== null && !!errors.array?.[editingIndex]) {
      console.warn(
        errors.array?.[editingIndex],
        " Невозможно применить изменения: есть ошибки в текущей строке."
      );
      return;
    }
    onApply();
    setIsButtonsDisabled(false);
  };

  const onApply = () => {
    if (editingIndex !== null) {
      const protocol = getValues(`array.${editingIndex}.protocol`);
      if (protocol === 2) {
        const rawPgnValue = String(
          getValues(`array.${editingIndex}.pgn`) || "0"
        );
        const pgnValue = parseInt(rawPgnValue, 10);
        const generatedCanId = `00${(pgnValue & 0xffff).toString(16).toUpperCase().padStart(4, "0")}00`;
        setValue(`array.${editingIndex}.canId`, generatedCanId);
      }
    }

    setIsApplied(true);
    setIsApplyButtonEnabled(false);
    setEditingIndex(null);
    setIsEditingRow(false);
    setIsEditing({});
  };

  const onSubmit: SubmitHandler<ArrayCanIdentifierDTO> = async (data) => {
    const uviToSend = currentUvi || props.selectedRow.uvi;
    if (!uviToSend) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    const standardFormatCount = data.array.filter(
      (item) => item.format === 1
    ).length;

    const extendedFormatCount = data.array.filter(
      (item) => item.format === 2
    ).length;

    if (standardFormatCount > 2) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({
          id: "can_format_validate_standard",
        }),
      });
      return;
    }

    if (extendedFormatCount > 13) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({
          id: "can_format_validate_extended",
        }),
      });
      return;
    }

    const updatedData = {
      array: data.array.map((item) => {
        const protocol = item.protocol;
        const pgnValue = parseInt(String(item.pgn || "0"), 10);
        const canId =
          protocol === 2
            ? `00${(pgnValue & 0xffff).toString(16).toUpperCase().padStart(4, "0")}00`
            : item.canId.replace(/^0x/, "");

        return new CanIdentifierDTO({
          ...item,
          canId,
          mask: item.mask.replace(/^0x/, ""),
        });
      }),
    };

    console.log("Отправка данных:", data);
    console.log("Ошибки при отправке формы:", errors);

    console.log("Submitting Data!!!:", updatedData);

    if (!isApplied) {
      onApply();
    }

    if (!currentUvi) {
      console.warn("Missing currentUvi, cannot proceed with submission.");
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    try {
      setIsButtonsDisabled(false);
      const result = await mapClient.updateCanIdentifiers(
        currentUvi,
        updatedData.array
      );

      if (result?.succeeded) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "changes_can_success" }),
        });

        reset({ array: updatedData.array });
        setEditingIndex(null);
        setIsEditing({});
        setIsEditingRow(false);
        setIsSendButtonDisabled(false);
        setIsApplyButtonEnabled(false);
        setCurrentUvi(props.selectedRow.uvi || currentUvi);

        await props.getFunctionsList(currentUvi, undefined);
      } else {
        console.error("Error response from API:", result);
        throw new Error(result?.message ?? "Failed to update");
      }
    } catch (error: any) {
      console.error("Error during request:", error);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({
          id: "changes_can_error",
        })}: ${error?.errors?.[0] ?? error?.message ?? "Unknown error"}`,
      });
    }
  };

  const addNewRecord = (format?: "Standard" | "j1939") => {
    if (!currentUvi) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    const protocolValue = format === "Standard" ? 1 : 2;

    const newRecord = new CanIdentifierDTO({
      id: undefined,
      uvi: currentUvi,
      index: undefined,
      canId: "",
      mask: protocolValue === 1 ? "0x7FF" : "0x03FFFF00",
      name: "",
      period: 0.001,
      protocol: protocolValue,
      format: protocolValue === 1 ? 1 : 2,
      configurationConfirmed: false,
    });

    append(newRecord);
    setEditingIndex(fields.length);
    setIsEditing({ [fields.length]: true });
    setIsApplyButtonEnabled(true);
    setIsButtonsDisabled(true);
    setIsEditingRow(true);
  };

  function removeRecord(arrayIndex: number) {
    remove(arrayIndex);
    setIsApplyButtonEnabled(false);
  }

  function onShowDeleteCan(arrayIndex: number, index: number | undefined) {
    setCanIdentifierToDelete(arrayIndex);
    setCanIdentifierCanIndexToDelete(index);
    setShowModalDeleteCan(true);
  }

  useEffect(() => {
    if (fields.length > 0) {
      trigger();
    }
  }, [fields.length, trigger]);

  useEffect(() => {
    setIsSendButtonDisabled((prev) => (prev !== !isValid ? !isValid : prev));
  }, [isValid]);

  useEffect(() => {
    if (typeof editingIndex === "number" && errors.array) {
      setIsApplyButtonEnabled(!errors.array[editingIndex]);
    }
  }, [errors.array, editingIndex]);

  useEffect(() => {
    setIsLoading(true);
    mapClient
      .getAllJ1939Parameters()
      .then((response) => {
        if (response.data) {
          setPgnOptions(
            response.data
              .filter((param) => param.pgn !== undefined)
              .map((param) =>
                param.pgn !== undefined ? param.pgn.toString() : ""
              )
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching PGN data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.selectedRow.uvi) {
      setCurrentUvi(props.selectedRow.uvi);
    }
  }, [props.selectedRow]);

  const handleFormatSelect = (format: "Standard" | "j1939") => {
    setSelectedFormat(format);
    setIsFormatModalOpen(false);
    addNewRecord(format);
  };

  const handleAddNewRecordClick = () => {
    setIsFormatModalOpen(true);
  };

  const columns: TableColumn<CanIdentifierDTO>[] = [
    {
      name: intl.formatMessage({ id: "can_name" }),
      selector: (can) => can.name ?? "",
      sortable: true,
      cell: (can, index) => (
        <div className="flex-wrap">
          <input
            {...register(`array.${index}.name`, {
              maxLength: {
                value: 45,
                message: intl.formatMessage(
                  { id: "max_length_error" },
                  { max: 45 }
                ),
              },
            })}
            className={`canId ${
              errors?.array?.[index]?.name ? "text-red-500" : ""
            } ${isEditing[index] ? "active-style" : "disabled-style"}`}
            id={`name-${index}`}
            type={"text"}
            placeholder={intl.formatMessage({
              id: "can_name_placeholder",
            })}
            style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              height: "50px",
            }}
            disabled={!isEditing[index]}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.name ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.name?.message}
              </p>
            ) : null}
          </div>
        </div>
      ),
      width: "170px",
    },
    {
      name: intl.formatMessage({ id: "canId" }),
      selector: (can) => can.canId ?? "",
      sortable: true,
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.canId`}
            control={control}
            rules={{
              required: intl.formatMessage({ id: "canId_validation" }),

              pattern: {
                value: /^[0-9a-fA-F]+$/,
                message: intl.formatMessage({ id: "canId_validation" }),
              },

              validate: (value) => {
                const cleanValue = value.startsWith("0x")
                  ? value.slice(2)
                  : value;

                if (!cleanValue) {
                  const message = intl.formatMessage({
                    id: "canId_validation",
                  });

                  return message;
                }

                const hexPattern = /^[0-9a-fA-F]+$/;
                if (!hexPattern.test(cleanValue)) {
                  const message = intl.formatMessage({
                    id: "canId_validation",
                  });

                  return message;
                }

                const allIds = getValues("array").map((item) => item.canId);
                const isDuplicate = allIds.some(
                  (id, idx) => id === value && idx !== index
                );
                if (isDuplicate) {
                  setIsApplyButtonEnabled(false);
                  const message = intl.formatMessage({
                    id: "error_message_id",
                  });

                  return message;
                }

                setIsApplyButtonEnabled(true);

                const maxValueExtendedTrue = 0x1fffffff;
                const maxValueExtendedFalse = 0x7ff;

                const format = getValues(`array.${index}.format`);
                const numericValue = parseInt(cleanValue, 16);

                if (format === 1) {
                  if (numericValue > maxValueExtendedFalse) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_false_maxValue",
                    });

                    return message;
                  }
                  if (cleanValue.length !== 3) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_false",
                    });

                    return message;
                  }
                } else if (format === 2) {
                  if (numericValue > maxValueExtendedTrue) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_true_maxValue",
                    });

                    return message;
                  }
                  if (cleanValue.length !== 8) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_true",
                    });

                    return message;
                  }
                }

                return true;
              },
            }}
            render={({ field }) => {
              const protocol = getValues(`array.${index}.protocol`) as number;
              const rawPgnValue = getValues(`array.${index}.pgn`);
              const pgnValue =
                rawPgnValue !== undefined ? String(rawPgnValue) : "";

              const canIdFromPgn =
                pgnValue && !isNaN(parseInt(pgnValue, 10))
                  ? `0x00${(parseInt(pgnValue, 10) & 0xffff).toString(16).toUpperCase().padStart(4, "0")}00`
                  : "";

              const displayValue =
                protocol === 2
                  ? canIdFromPgn
                  : field.value
                    ? `0x${field.value.replace(/^0x/, "")}`
                    : "";

              return (
                <input
                  {...field}
                  type="text"
                  value={displayValue || ""}
                  id={`canId-${index}`}
                  placeholder=""
                  className={`canId ${
                    isEditing[index] ? "text-normal" : "text-gray-400"
                  } ${protocol === 2 ? "opacity-0" : ""}`}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                  disabled={
                    protocol === 2 ||
                    !isEditing[index] ||
                    (editingIndex !== index &&
                      (!!can.canId || getValues(`array.${index}.period`) === 0))
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/^0x/, "") || "";
                    field.onChange(inputValue);

                    trigger(`array.${index}.canId`);
                  }}
                />
              );
            }}
          />
          <div className="mt-2">
            {getValues(`array.${index}.protocol`) === 1 &&
            (errors?.array?.[index]?.canId ||
              !getValues(`array.${index}.canId`) ||
              "") ? (
              <p className="">
                {errors?.array?.[index]?.canId?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>
        </div>
      ),
      width: "120px",
    },
    {
      name: "PGN",
      selector: (can) => can.pgn ?? "",
      sortable: true,
      cell: (can, index) => {
        return (
          <div className="flex-wrap">
            <Controller
              name={`array.${index}.pgn`}
              control={control}
              rules={{
                validate: (value) => {
                  const protocol = getValues(`array.${index}.protocol`);
                  const stringValue = String(value || "");

                  if (protocol === 2 && !value) {
                    return intl.formatMessage({ id: "input_required" });
                  }

                  if (protocol === 2) {
                    if (!/^\d{5}$/.test(String(value || ""))) {
                      return intl.formatMessage({ id: "pgn_validation" });
                    }
                  }

                  if (stringValue.trim()) {
                    const allPgnValues = getValues("array").map((item) =>
                      String(item.pgn || "")
                    );
                    const isDuplicate =
                      allPgnValues.filter(
                        (pgn, idx) => pgn === stringValue && idx !== index
                      ).length > 0;

                    if (isDuplicate) {
                      return intl.formatMessage({ id: "pgn_duplicate_error" });
                    }
                  }

                  return true;
                },
              }}
              render={({ field }) => {
                const protocol = getValues(`array.${index}.protocol`);
                return (
                  <input
                    {...field}
                    list={`pgn-options-${index}`}
                    type="text"
                    value={field.value ?? ""}
                    id={`pgn-${index}`}
                    placeholder=""
                    className={`canId ${
                      isEditing[index] ? "text-normal" : "text-gray-400"
                    }`}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      boxShadow: "none",
                    }}
                    disabled={
                      protocol === 1 ||
                      !isEditing[index] ||
                      (editingIndex !== index &&
                        (!!can.canId ||
                          getValues(`array.${index}.period`) === 0))
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      field.onChange(newValue);

                      if (protocol === 2) {
                        const pgnValue = parseInt(newValue || "0", 10);
                        const generatedCanId = `00${(pgnValue & 0xffff).toString(16).toUpperCase()}00`;

                        setValue(`array.${index}.canId`, generatedCanId);
                      }

                      trigger(`array.${index}.canId`);
                    }}
                  />
                );
              }}
            />
            <datalist id={`pgn-options-${index}`}>
              {isLoading ? (
                <option>
                  {intl.formatMessage({ id: "status_loading_update" })}
                </option>
              ) : (
                [...new Set(pgnOptions)].map((pgn, idx) => (
                  <option key={`${pgn}-${idx}`} value={pgn}>
                    {pgn}
                  </option>
                ))
              )}
            </datalist>
            <div className="mt-2">
              {getValues(`array.${index}.protocol`) === 2 &&
              (errors?.array?.[index]?.pgn ||
                !getValues(`array.${index}.pgn`)) ? (
                <p className="">
                  {errors?.array?.[index]?.pgn?.message ||
                    intl.formatMessage({ id: "input_required" })}
                </p>
              ) : null}
            </div>
          </div>
        );
      },
      width: "130px",
    },
    {
      name: intl.formatMessage({ id: "can_mask" }),
      selector: (can) => can.mask ?? "",
      sortable: true,
      cell: (can, index) => {
        const formatValue = watch(`array.${index}.format`);

        return (
          <div className="flex-wrap">
            <input
              {...register(`array.${index}.mask`, {})}
              className={`canId ${errors?.array?.[index]?.mask ? "text-red-500" : ""} 
                          ${isEditing[index] ? "active-style" : "disabled-style"}`}
              id={`mask-${index}`}
              type={"text"}
              style={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                height: "50px",
              }}
              disabled={
                !isEditing[index] || formatValue === 1 || formatValue === 2
              }
            />
            <div className="mt-2">
              {(errors?.array ?? [])[index]?.mask ? (
                <p className="text-red-500">
                  {errors?.array?.[index]?.mask?.message}
                </p>
              ) : null}
            </div>
          </div>
        );
      },
      width: "120px",
    },
    {
      name: intl.formatMessage({ id: "protocol" }),
      selector: (can) => can.protocol ?? "",
      sortable: true,
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.protocol`}
            control={control}
            render={({ field: { value } }) => {
              const protocolLabel = value === 1 ? "Standard" : "J1939";

              useEffect(() => {
                if (value === 1 && getValues(`array.${index}.format`) === 1) {
                  setValue(`array.${index}.mask`, "0x7FF");
                } else if (
                  value === 1 &&
                  getValues(`array.${index}.format`) === 2
                ) {
                  setValue(`array.${index}.mask`, "0x1FFFFFFF");
                } else if (value === 2) {
                  setValue(`array.${index}.mask`, "0x03FFFF00");
                  setValue(`array.${index}.format`, 2);
                }
              }, [value, index]);

              return (
                <div
                  className={`canId ${
                    isEditing[index] ? "active-style" : "disabled-style"
                  }`}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    alignContent: "center",
                  }}
                >
                  {protocolLabel}
                </div>
              );
            }}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.protocol ||
            !getValues(`array.${index}.protocol`) ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.protocol?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>{" "}
        </div>
      ),
      width: "130px",
    },
    {
      name: intl.formatMessage({ id: "can_period" }),
      selector: (can) => can.period ?? "",
      sortable: true,
      cell: (can, index) => (
        <div className="flex-wrap">
          <input
            {...register(`array.${index}.period`, {
              required: intl.formatMessage({ id: "can_input_required" }),
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: intl.formatMessage({ id: "can_period_validation" }),
              },
              validate: (value) => {
                const numericValue = parseFloat((value || "").toString());
                if (isNaN(numericValue)) {
                  return intl.formatMessage({ id: "can_period_validation" });
                }
                if (numericValue < 0.001) {
                  return intl.formatMessage({ id: "can_period_min_error" });
                }
                return true;
              },
            })}
            className={`canId ${
              errors?.array?.[index]?.period ? "text-red-500" : ""
            } ${isEditing[index] ? "active-style" : "disabled-style"}`}
            id={`period-${index}`}
            type={"text"}
            placeholder={intl.formatMessage({
              id: "placeholder_choose_period",
            })}
            style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              height: "50px",
            }}
            disabled={!isEditing[index]}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.period ||
            !getValues(`array.${index}.period`) ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.period?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>{" "}
        </div>
      ),
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "can_type_extended" }),
      selector: (can) => can.format ?? "",
      sortable: true,
      cell: (can, index) => {
        const protocolWatch = watch(`array.${index}.protocol`);

        return (
          <div className="flex-wrap">
            <Controller
              name={`array.${index}.format`}
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <select
                  name={name}
                  disabled={
                    !isEditing[index] ||
                    protocolWatch === 2 ||
                    getValues(`array.${index}.protocol`) === 2 ||
                    (editingIndex !== index &&
                      (!!can.canId || getValues(`array.${index}.period`) === 0))
                  }
                  onChange={(e) => {
                    const selectedFormat = Number(e.target.value);
                    onChange(selectedFormat);

                    trigger(`array.${index}.canId`);

                    const currentProtocol = getValues(
                      `array.${index}.protocol`
                    );
                    if (currentProtocol === 1 && selectedFormat === 1) {
                      setValue(`array.${index}.mask`, "0x7FF");
                    } else if (currentProtocol === 1 && selectedFormat === 2) {
                      setValue(`array.${index}.mask`, "0x1FFFFFFF");
                    } else if (currentProtocol === 2 && selectedFormat === 2) {
                      setValue(`array.${index}.mask`, "0x03FFFF00");
                    }
                  }}
                  value={value || ""}
                  className={`canId ${isEditing[index] ? "text-normal" : "text-gray-400"}`}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    padding: "5px",
                  }}
                >
                  <option value="1">11 - bit (Standard)</option>
                  <option value="2">29 - bit (Extended)</option>
                </select>
              )}
            />
            <div className="mt-2">
              {(errors?.array ?? [])[index]?.format && (
                <p>
                  {(errors?.array ?? [])[index]?.format?.message || "Errors!!!"}
                </p>
              )}
            </div>
          </div>
        );
      },
      width: "110px",
    },
    {
      name: intl.formatMessage({ id: "can_action" }),
      cell: (can, arrayIndex) => {
        return (
          <div className="flex justify-center items-center mb-2.5">
            <DeleteCan
              width="20"
              height="20"
              onClick={() => onShowDeleteCan(arrayIndex, can.index)}
            />
            {editingIndex !== arrayIndex ? (
              <EditCan
                className="ml-2"
                width="17"
                height="17"
                onClick={() => {
                  startEditing(arrayIndex);
                  applyRow();
                }}
              />
            ) : (
              <ApplyBtn
                type="button"
                className={`ml-2 ${isApplyButtonEnabled ? "" : "disabled"}`}
                width="20"
                height="20"
                onClick={editRow}
                disabled={!isApplyButtonEnabled}
              />
            )}
          </div>
        );
      },
      width: "auto",
    },
  ];

  return (
    <>
      {showModalDeleteCan ? (
        <div>
          <ModalNotification
            onYesModalActions={() => {
              setShowModalDeleteCan(false);
            }}
            modalQuestion={intl.formatMessage({
              id: "delete_can_identifier_question",
            })}
            onNoModalActions={() => {
              removeRecord(canIdentifierToDelete!);
              setShowModalDeleteCan(false);
            }}
          />
        </div>
      ) : null}

      {showModalCancelAction ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalCancelAction(false)}
            modalQuestion={intl.formatMessage({
              id: "cancel_action_question",
            })}
            onNoModalActions={() => props.setShowModalCan(false)}
          />
        </div>
      ) : null}

      <div className="modal_can">
        <div className="modal_can_container">
          <form onSubmit={handleSubmit(onSubmit)} className="modal_can_form">
            <div className="modal_can_header">
              <div className="modal_can_form_title">
                <h3>
                  <FormattedMessage id="changes_CanId" />
                </h3>
                <img
                  src="/image/btn-remove/btn-remove.svg"
                  alt="Close form"
                  onClick={() => props.setShowModalCan(false)}
                />
              </div>
            </div>
            <div className="modal_can_form_body mt-3.5">
              <div className="flex justify-start">
                <button
                  type="button"
                  className={`add_new_record ${isButtonsDisabled ? "disabled" : ""}`}
                  disabled={isButtonsDisabled}
                  onClick={handleAddNewRecordClick}
                >
                  <AddNewCan className="ml-2.5" />
                  <span className="ml-2.5">
                    {intl.formatMessage({ id: "add_can" })}
                  </span>
                </button>
              </div>
              <div className="mt-3.5">
                <DataTable
                  columns={columns}
                  data={fields}
                  noDataComponent={intl.formatMessage({ id: "empty_table" })}
                  fixedHeader
                  striped={false}
                  fixedHeaderScrollHeight="450px"
                  pointerOnHover={true}
                  persistTableHead
                  customStyles={customStylesCan}
                />
              </div>
            </div>

            <div className="flex gap-1 items-center mt-2 text-sm text-color16">
              <FormattedMessage id="can_downloaded" /> {confirmedCount}{" "}
              <FormattedMessage id="can_from" /> {totalCount}{" "}
              <CanConfirmedSuccess className="w-6 h-6" />{" "}
              {confirmedCount !== totalCount && (
                <>
                  {" "}
                  <FormattedMessage id="can_unconfirmed" />{" "}
                  {totalCount - confirmedCount}{" "}
                  <button
                    onClick={openModal}
                    type="button"
                    className="underline underline-offset-2 text-color14"
                  >
                    <FormattedMessage id="can_read_more" />
                  </button>
                  <CanConfirmedError className="w-6 h-6" />
                </>
              )}
              {isOpenModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                  <div className="relative bg-white rounded-lg p-6 max-w-sm mx-auto text-center">
                    <button
                      className="absolute top-4 right-4"
                      onClick={closeModal}
                    >
                      <CloseBtn />
                    </button>
                    <div className="flex justify-center mb-2">
                      <WarningIcon className="w-10 h-10" />
                    </div>
                    <div className="text-xl font-bold text-color15 mb-3">
                      <FormattedMessage id="can_attention" />
                    </div>
                    <div className="text-sm text-color8">
                      <div className="">
                        CAN ID{" "}
                        {unconfirmedCanIds.length > 0 && (
                          <span className="underline underline-offset-2 text-color10">
                            {unconfirmedCanIds.join(",")}
                          </span>
                        )}{" "}
                        <FormattedMessage id="can_attention_extends" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="modal_can_form_footer mt-5">
              <div className="flex items-center">
                <button
                  type="submit"
                  className={`btn ${isSendButtonDisabled ? "disabled" : ""}`}
                  disabled={isSendButtonDisabled}
                >
                  <span className="ml-2">
                    <FormattedMessage id="save" />
                  </span>
                </button>

                <div className="ml-7 mr-7">
                  <button
                    className={`cancel ${isButtonsDisabled ? "disabled" : ""}`}
                    disabled={isButtonsDisabled}
                    type="button"
                    onClick={() => setShowModalCancelAction(true)}
                  >
                    <span>
                      <FormattedMessage id="cancel" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <FormatSelectionModal
        isOpen={isFormatModalOpen}
        onClose={() => setIsFormatModalOpen(false)}
        onSelect={handleFormatSelect}
      />
    </>
  );
}

export default CanIdentifier;
