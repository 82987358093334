import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import { useIntl } from "react-intl";
import L from "leaflet";
import { mapClient } from "../../api/auth/AxiosInstanse";
import { HubConnectionBuilder, JsonHubProtocol } from "@microsoft/signalr";
import "./ChronologyModal.scss";

interface ChronologyModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  mapCenter: [number, number];
  markerCoordinates?: [number, number];
  uvi?: string;
}

const markerIcon = L.icon({
  iconUrl: "./image/truckIcons/iconBus.svg",
  iconSize: [40, 40],
});

const markerEllipsePosition = L.icon({
  iconUrl: "./image/Ellipse_from_modal.svg",
  iconSize: [15, 15],
});

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${day}.${month}.${year}<br />${hours}:${minutes}:${seconds}`;
};

const ChronologyModal: React.FC<ChronologyModalProps> = ({
  isOpen,
  onRequestClose,
  mapCenter,
  markerCoordinates,
  uvi,
}) => {
  const intl = useIntl();

  const getTodayStart = () => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    return today.toISOString().slice(0, 16);
  };

  const getTodayEnd = () => {
    const todayEnd = new Date();
    todayEnd.setUTCHours(23, 59, 0, 0);
    return todayEnd.toISOString().slice(0, 16);
  };

  const [startDateTime, setStartDateTime] = useState(getTodayStart);
  const [endDateTime, setEndDateTime] = useState(getTodayEnd);
  const [accuracy, setAccuracy] = useState("10м");
  const [error, setError] = useState<string | null>(null);
  const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);
  const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);
  const [path, setPath] = useState<
    Array<{ position: [number, number]; date: string }>
  >([]);
  const [center, setCenter] = useState<[number, number]>(mapCenter);
  const [currentCoordinates, setCurrentCoordinates] =
    useState<[number, number]>(mapCenter);

  useEffect(() => {
    if (!isOpen) return;

    const protocol = new JsonHubProtocol();
    const connection = new HubConnectionBuilder()
      .withUrl("https://platform.sayoratec.com:160/coordinates", {
        accessTokenFactory: () => localStorage.getItem("token") || "",
      })
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .build();

    connection
      .start()
      .then(() => {
        // console.log("SignalR Connected");
        connection.on(
          "SendCoordinatesAsync",
          (message: {
            uvi: string;
            coordinatesDTO: { longitude: number; latitude: number };
          }) => {
            if (message.uvi === uvi) {
              const newCoordinates: [number, number] = [
                message.coordinatesDTO.latitude,
                message.coordinatesDTO.longitude,
              ];
              setCurrentCoordinates(newCoordinates);
            }
          }
        );
      })
      .catch((err) => console.error("SignalR connection error:", err));

    return () => {
      connection.stop();
    };
  }, [isOpen, uvi]);

  useEffect(() => {
    if (!isOpen) {
      setPath([]);
      setCenter(mapCenter);
    }
  }, [isOpen, mapCenter]);

  useEffect(() => {
    setCenter(mapCenter);
  }, [uvi, mapCenter]);

  useEffect(() => {
    setStartDateTime(getTodayStart());
    setEndDateTime(getTodayEnd());
  }, [isOpen]);

  useEffect(() => {
    if (new Date(startDateTime) > new Date(endDateTime)) {
      const newStartDate = new Date(endDateTime);
      newStartDate.setDate(newStartDate.getDate() - 1);
      setStartDateTime(newStartDate.toISOString().slice(0, 16));
    }
  }, [endDateTime, startDateTime]);

  useEffect(() => {
    setCurrentCoordinates(mapCenter);
  }, [uvi]);

  const handleStartDateTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDateTime(e.target.value);
    setIsStartDateEmpty(false);
  };

  const handleEndDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateTime(e.target.value);
    setIsEndDateEmpty(false);
  };

  const handleAccuracyChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setAccuracy(e.target.value);

  const handleApply = async () => {
    if (!startDateTime) {
      setIsStartDateEmpty(true);
    }
    if (!endDateTime) {
      setIsEndDateEmpty(true);
    }

    if (!startDateTime || !endDateTime) {
      setError("Заполните все поля");
      return;
    }

    if (new Date(startDateTime) > new Date(endDateTime)) {
      setError("Начальная дата не может быть больше конечной");
      return;
    }

    setError(null);

    try {
      setPath([]);

      const firstDate = new Date(startDateTime);
      const lastDate = new Date(endDateTime);
      const kink = parseInt(accuracy, 10);

      const response = await mapClient.getVehicleRoute(
        uvi,
        firstDate,
        lastDate,
        kink
      );

      if (Array.isArray(response.data)) {
        const coordinatesWithDate = response.data.map((coord) => {
          const dateStr =
            typeof coord.messageDate === "string"
              ? coord.messageDate
              : (coord.messageDate?.toISOString() ?? "Неизвестно");

          const date = formatDate(dateStr);

          return {
            position: [
              coord.coordinatesDTO?.latitude ?? 0,
              coord.coordinatesDTO?.longitude ?? 0,
            ] as [number, number],
            date,
          };
        });

        setPath(coordinatesWithDate);

        if (coordinatesWithDate.length > 0) {
          setCenter(coordinatesWithDate[0].position);
        }
      } else {
        console.log("Ответ от сервера не является массивом:", response.data);
      }
    } catch (error) {
      console.error("Ошибка при выполнении запроса:", error);
      setError("Ошибка при загрузке данных");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="chronology-modal"
      overlayClassName="chronology-overlay"
      contentLabel="Chronology Modal"
    >
      <div className="modal-header">
        <h2>{intl.formatMessage({ id: "chronology" })}</h2>
        <img
          onClick={onRequestClose}
          className="modal-close-button"
          src="/image/btn-remove/btn-remove.svg"
          alt="×"
        />
      </div>
      <div className="controls">
        <label className="controls-label">
          <p className="controls-params">
            {intl.formatMessage({ id: "can_period" })}:
          </p>
          <input
            className={`controls-select ${isStartDateEmpty ? "error" : ""}`}
            type="datetime-local"
            value={startDateTime}
            onChange={handleStartDateTimeChange}
          />
        </label>
        <label className="controls-label">
          <p className="controls-params">-</p>
          <input
            className={`controls-select ${isEndDateEmpty ? "error" : ""}`}
            type="datetime-local"
            value={endDateTime}
            onChange={handleEndDateTimeChange}
          />
        </label>
        <label className="controls-label controls-label-gps">
          <p className="controls-params">
            {intl.formatMessage({ id: "accuracyGps" })}
          </p>
          <select
            className="controls-select"
            value={accuracy}
            onChange={handleAccuracyChange}
          >
            <option value="10м">10м</option>
            <option value="50м">50м</option>
            <option value="100м">100м</option>
          </select>
        </label>
        <div className="controls-footer">
          <button className="btn applyButton" onClick={handleApply}>
            {intl.formatMessage({ id: "apply" })}
          </button>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="modal-content">
        <div className="map-container">
          <MapContainer
            center={center}
            zoom={10}
            style={{ height: "592px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={currentCoordinates} icon={markerIcon} />{" "}
            {/* <button
              className="btn applyButton"
              style={{
                zIndex: "1001",
                position: "absolute",
                right: "10px",
                top: "10px",
                fontSize: "16px",
                width: "130px",
              }}
            >
              <TransportIcon width="25" height="25" />
              {intl.formatMessage({ id: "transport_name_title" })}
            </button> */}
            <Polyline
              positions={path.map((p) => p.position)}
              color="rgba(255, 0, 0, 1)"
              weight={2}
            />
            {path.map(({ position, date }, index) => (
              <Marker
                key={index}
                position={position}
                icon={markerEllipsePosition}
              >
                <Popup className="popup-chronology-modal">
                  <div dangerouslySetInnerHTML={{ __html: date }} />
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </Modal>
  );
};

export default ChronologyModal;
